import env from './env';

class UxtIcon extends HTMLElement {
  static get observedAttributes() {
    return [PROP_ICON, PROP_SIZE, PROP_COLOR];
  }

  static icons = {};
  static fetching = {};

  attributeChangedCallback(name) {
    if (name === PROP_ICON) {
      this.updateIcon();
    } else if (name === PROP_SIZE && this.svg) {
      this.updateSize();
    } else if (name === PROP_COLOR && this.svg) {
      this.updateColor();
    }
  }

  set icon(icon) {
    this.setAttribute(PROP_ICON, icon);
  }
  set size(size) {
    this.setAttribute(PROP_SIZE, size);
  }
  set color(color) {
    this.setAttribute(PROP_COLOR, color);
  }

  svgUrl(name) {
    return `${env.baseUrl}${env.externalPath}/icons/${name}.svg`;
  }

  updateIcon() {
    const icon = this.getAttribute(PROP_ICON);
    if (icon.match(/^[a-z0-9-]+$/i)) {
      if (UxtIcon.icons[icon]) {
        // The SVG has already been loaded so use the cached version
        this.innerHTML = UxtIcon.icons[icon];
        this.afterIconUpdate();
      } else if (UxtIcon.fetching[icon]) {
        // The SVG is being loaded so wait for the cached version to load
        document.addEventListener(
          'uxt-icon-fetch',
          (e) => {
            if (e.detail.icon === icon) {
              this.innerHTML = UxtIcon.icons[icon];
              this.afterIconUpdate();
            }
          },
          false
        );
      } else {
        // The SVG has not been loaded, so start loading it
        UxtIcon.fetching[icon] = true;
        fetch(this.svgUrl(icon))
          .then((res) => res.text())
          .then((svgMarkup) => {
            UxtIcon.fetching[icon] = false;
            if (svgMarkup.match(/<\/?(script|style|link|img)/gs, '')) {
              // We have control over the urls and the content of the urls, so this should
              // never happen. But just in case, we check for script, style, link, and img tags.
              console.error(`Not displaying the svg because it contained non svg html tags: ${this.svgUrl(icon)}`);
            } else if (!svgMarkup.match(/<(svg)/gs, '')) {
              console.error(`Not displaying the svg because it did not contain a svg html tag: ${this.svgUrl(icon)}`);
            } else {
              UxtIcon.icons[icon] = svgMarkup;
              document.dispatchEvent(new CustomEvent('uxt-icon-fetch', { detail: { icon: icon } }));
              this.innerHTML = svgMarkup;
              this.afterIconUpdate();
            }
          });
      }
    } else {
      console.error(
        `Invalid icon name: ${icon}. Icon names must container only numbers, lower case letters, and the '-' symbol.`
      );
    }
  }

  afterIconUpdate() {
    this.svg = this.querySelector('svg');
    this.svg.setAttribute('class', 'mh-icon');
    // Always ensure SVG initial width and height to match its inherent dimensions.
    // Note that our SVGs don鈥檛 have this set by default so we do it here.
    // Consider updating SVGs to include width and height so we can remove it here.
    // Commenting out initial attribute sizing.
    this.svg.setAttribute('width', '16px');
    this.svg.setAttribute('height', '16px');
    this.updateSize();
    this.updateColor();
  }

  updateSize() {
    const size = this.getAttribute(PROP_SIZE);
    if (size) {
      this.svg.setAttribute('style', `width:${sizes[size]};height:${sizes[size]};`);
    }
  }

  updateColor() {
    const color = this.getAttribute(PROP_COLOR);
    if (color) {
      this.svg.setAttribute('class', `text-${color}`);
    }
  }
}

const sizes = {
  xs: '0.75em',
  sm: '0.875em',
  md: '1em',
  lg: '1.25em',
  xl: '1.5em',
  '2xl': '2em',
  '3xl': '2.5em',
};

const PROP_ICON = 'icon';
const PROP_SIZE = 'size';
const PROP_COLOR = 'color';

if (!window.customElements.get('uxt-icon')) {
  window.customElements.define('uxt-icon', UxtIcon);
}
